import { DADATA_TOKEN, DADATA_URL, URL } from './constants';
import { getToken, makeRequest } from './utils';

export const getUserInfoRequest = () =>
	makeRequest(`${URL}/api/auth/me`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getUserBadgesRequest = () =>
	makeRequest(`${URL}/api/auth/me/badges`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const setUserInfoRequest = body =>
	makeRequest(`${URL}/api/auth/me`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

export const createUserRequest = payload =>
	makeRequest(`${URL}/api/auth/register`, {
		method: 'POST',
		headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
		body: JSON.stringify({ ...payload }),
	});

export const getCompaniesBySearchRequest = payload =>
	makeRequest(`${DADATA_URL}/suggest/party`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Token ${DADATA_TOKEN}`,
		},
		body: JSON.stringify({ query: payload }),
	});

export const authorizeUserRequest = payload =>
	makeRequest(`${URL}/api/auth/login`, {
		method: 'POST',
		headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
		body: JSON.stringify({ ...payload }),
	});

export const forgotPasswordRequest = payload =>
	makeRequest(`${URL}/api/auth/password/forgot`, {
		method: 'POST',
		headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
		body: JSON.stringify({ ...payload }),
	});

export const resetPasswordRequest = payload =>
	makeRequest(`${URL}/api/auth/password/reset`, {
		method: 'POST',
		headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
		body: JSON.stringify({ ...payload }),
	});

export const getTrailersListRequest = parameters =>
	makeRequest(
		`${URL}/api/trailers`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getTrailerInfoRequest = id =>
	makeRequest(`${URL}/api/trailers/${id}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const deleteTrailerRequest = id =>
	makeRequest(`${URL}/api/trailers/${id}`, {
		method: 'DELETE',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const saveNewTrailerRequest = body =>
	makeRequest(`${URL}/api/trailers`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

export const updateTrailerRequest = (id, body) =>
	makeRequest(`${URL}/api/trailers/${id}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

export const getDetailsRequest = parameters =>
	makeRequest(
		`${URL}/api/details`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getDetailInfoRequest = id =>
	makeRequest(`${URL}/api/details/${id}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const createDetailRequest = payload =>
	makeRequest(`${URL}/api/details`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const updateDetailDefaultRequest = (id, payload) =>
	makeRequest(`${URL}/api/details/${id}/default`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const updateDetailRequest = (id, payload) =>
	makeRequest(`${URL}/api/details/${id}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const deleteDetailRequest = id =>
	makeRequest(`${URL}/api/details/${id}`, {
		method: 'DELETE',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getDetailsBySearchRequest = payload =>
	makeRequest(`${DADATA_URL}/suggest/bank`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Token ${DADATA_TOKEN}`,
		},
		body: JSON.stringify({ query: payload }),
	});

export const getCarsListRequest = parameters =>
	makeRequest(
		`${URL}/api/cars`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getReqsCarsListRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}/selectCar`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getCarInfoRequest = id =>
	makeRequest(`${URL}/api/cars/${id}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const deleteCarRequest = id =>
	makeRequest(`${URL}/api/cars/${id}`, {
		method: 'DELETE',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const createCarRequest = body =>
	makeRequest(`${URL}/api/cars`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

export const updateCarRequest = (id, body) =>
	makeRequest(`${URL}/api/cars/${id}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

export const getPeriodsDictionaryRequest = parameters =>
	makeRequest(
		`${URL}/api/periods`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getCompaniesTypesDictionaryRequest = parameters =>
	makeRequest(
		`${URL}/api/companies_types`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getDeliveryStatusesDictionaryRequest = parameters =>
	makeRequest(
		`${URL}/api/delivery_statuses`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getTypesDictionaryRequest = parameters =>
	makeRequest(
		`${URL}/api/types`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getFeaturesDictionaryRequest = parameters =>
	makeRequest(
		`${URL}/api/features`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getTenderTypesRequest = parameters =>
	makeRequest(
		`${URL}/api/tender_types`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getTenderCompaniesRequest = parameters =>
	makeRequest(
		`${URL}/api/tender_companies`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getModesDictionaryRequest = parameters =>
	makeRequest(
		`${URL}/api/modes`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getRatesDictionaryRequest = parameters =>
	makeRequest(
		`${URL}/api/rates`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getTonnagesDictionaryRequest = parameters =>
	makeRequest(
		`${URL}/api/tonnages`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getCarTypesRequest = parameters =>
	makeRequest(
		`${URL}/api/cars_types`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getStatusesDictionaryRequest = () =>
	makeRequest(`${URL}/api/statuses`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getCompaniesDictionaryRequest = parameters =>
	makeRequest(
		`${URL}/api/companies`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getCompanyRequest = id =>
	makeRequest(`${URL}/api/companies/${id}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const updateCompanyRequest = (id, body) =>
	makeRequest(`${URL}/api/companies/${id}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

export const getAddressesRequest = parameters =>
	makeRequest(
		`${URL}/api/addresses`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getAddressInfoRequest = id =>
	makeRequest(`${URL}/api/addresses/${id}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const createAddressRequest = payload =>
	makeRequest(`${URL}/api/addresses`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const updateAddressRequest = (id, payload) =>
	makeRequest(`${URL}/api/addresses/${id}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const deleteAddressRequest = id =>
	makeRequest(`${URL}/api/addresses/${id}`, {
		method: 'DELETE',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getAddressesBySearchRequest = payload =>
	makeRequest(`${DADATA_URL}/suggest/address`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Token ${DADATA_TOKEN}`,
		},
		body: JSON.stringify({ query: payload }),
	});

export const getContactsRequest = (parameters, addressId) =>
	makeRequest(
		`${URL}/api/addresses/${addressId}/contacts`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getContactInfoRequest = (addressId, contactId) =>
	makeRequest(`${URL}/api/addresses/${addressId}/contacts/${contactId}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const createContactRequest = (payload, addressId) =>
	makeRequest(`${URL}/api/addresses/${addressId}/contacts`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const updateContactRequest = (payload, addressId, contactId) =>
	makeRequest(`${URL}/api/addresses/${addressId}/contacts/${contactId}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const deleteContactRequest = (addressId, contactId) =>
	makeRequest(`${URL}/api/addresses/${addressId}/contacts/${contactId}`, {
		method: 'DELETE',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const updateContactDefaultRequest = (payload, addressId, contactId) =>
	makeRequest(`${URL}/api/addresses/${addressId}/contacts/${contactId}/default`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const getUsersListRequest = parameters =>
	makeRequest(
		`${URL}/api/users`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getRelationshipListRequest = parameters =>
	makeRequest(
		`${URL}/api/company_relationship`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getUserRequest = id =>
	makeRequest(`${URL}/api/users/${id}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const deleteUserRequest = id =>
	makeRequest(`${URL}/api/users/${id}`, {
		method: 'DELETE',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const createUserItemRequest = body =>
	makeRequest(`${URL}/api/users`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

export const updateUserRequest = (id, body) =>
	makeRequest(`${URL}/api/users/${id}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

export const getRelationshipRequest = id =>
	makeRequest(`${URL}/api/company_relationship/${id}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const deleteRelationshipRequest = id =>
	makeRequest(`${URL}/api/company_relationship/${id}`, {
		method: 'DELETE',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getShippersDictionaryRequest = parameters =>
	makeRequest(
		`${URL}/api/companies/shippers`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getCarriersDictionaryRequest = parameters =>
	makeRequest(
		`${URL}/api/companies/cargo`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const createRelationshipItemRequest = body =>
	makeRequest(`${URL}/api/company_relationship`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

export const updateRelationshipRequest = (id, body) =>
	makeRequest(`${URL}/api/company_relationship/${id}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

export const getUsersRolesListRequest = parameters =>
	makeRequest(
		`${URL}/api/users_roles`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getUserRoleRequest = id =>
	makeRequest(`${URL}/api/users_roles/${id}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const deleteUserRoleRequest = id =>
	makeRequest(`${URL}/api/users_roles/${id}`, {
		method: 'DELETE',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const createUserRoleRequest = body =>
	makeRequest(`${URL}/api/users_roles`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

export const updateUserRoleRequest = (id, body) =>
	makeRequest(`${URL}/api/users_roles/${id}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

export const getRoutesRequest = () =>
	makeRequest(`${URL}/api/routes`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getReqsRequest = (id, parameters) =>
	makeRequest(
		`${URL}/api${id}`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getReqsFilterRequest = (id, parameters) =>
	makeRequest(
		`${URL}/api${id}/filter`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const deleteReqRequest = (id, pathname) =>
	makeRequest(`${URL}/api${pathname}/${id}`, {
		method: 'DELETE',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const createMyReqRequest = (body, pathname) =>
	makeRequest(`${URL}/api${pathname}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

export const recreateReqRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}/recreate`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
	});

export const updateMyReqWithPayloadRequest = (id, field, body) =>
	makeRequest(`${URL}/api/reqs/${id}${field}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	});

export const updateMyReqRequest = (id, field, body, pathname) =>
	makeRequest(`${URL}/api${pathname}/${id}/${field}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
		...(body ? { body: JSON.stringify(body) } : {}),
	});

export const getReqDetailsRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const takeReqRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}/take`, {
		method: 'PATCH',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getRequestSelectionRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}/selectCompany`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getRequestsCountRequest = () =>
	makeRequest(`${URL}/api/reqs/count`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getNotificationsListRequest = () =>
	makeRequest(`${URL}/api/notifications`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const markNotificationRequest = id =>
	makeRequest(`${URL}/api/notifications/${id}/mark`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getRequestDriversRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}/selectDriver`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getEmailVerifyInfoRequest = () =>
	makeRequest(`${URL}/api/auth/email/verify`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const emailResendRequest = () =>
	makeRequest(`${URL}/api/auth/email/resend`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
			'Content-Type': 'application/json',
		},
	});

export const emailVerifyRequest = (id, hash) =>
	makeRequest(`${URL}/api/auth/email/verify/${id}/${hash}`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getAuditDataRequest = (entity, id) =>
	makeRequest(`${URL}/api/${entity}${id ? `/${id}` : ''}/audit`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getReqDocsRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}/documents/group`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getReqEventRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}/event`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getSelectDoneRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}/selectDone`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const updateSelectDoneRequest = (idSelectDone, idSelectPoint, payload) =>
	makeRequest(`${URL}/api/reqs/${idSelectDone}/points/${idSelectPoint}/setDone`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const getSelectFlightRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}/selectFlight`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const specifyFlightRequest = (id, payload) =>
	makeRequest(`${URL}/api/reqs/${id}/setFlight`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const getSelectProductInvoiceRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}/selectProductInvoice`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const setProductInvoiceRequest = (id, payload) =>
	makeRequest(`${URL}/api/reqs/${id}/setProductInvoice`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const getSelectChosenCompanyRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}/selectChosenCompany`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const setChosenCompanyRequest = (id, payload) =>
	makeRequest(`${URL}/api/reqs/${id}/setChosenCompany`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const getInformationRequest = parameters =>
	makeRequest(
		`${URL}/api/home`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const getPointDocumentsRequest = (reqId, pointId, parameters) =>
	makeRequest(
		`${URL}/api/reqs/${reqId}/points/${pointId}/selectDocuments`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const setPointDocumentsRequest = (reqId, pointId, body) =>
	makeRequest(`${URL}/api/reqs/${reqId}/points/${pointId}/setDocuments`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		...(body ? { body } : {}),
	});

export const getDocumentsRequest = (reqId, parameters) =>
	makeRequest(
		`${URL}/api/reqs/${reqId}/selectDocuments`,
		{
			method: 'GET',
			headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
		},
		parameters,
	);

export const setDocumentsRequest = (reqId, body) =>
	makeRequest(`${URL}/api/reqs/${reqId}/setDocuments`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		...(body ? { body } : {}),
	});

export const getChatsListRequest = () =>
	makeRequest(`${URL}/api/chats`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getSelectCompanyNoteRequest = id =>
	makeRequest(`${URL}/api/reqs/${id}/selectCompanyNote`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const setCompanyNoteRequest = (id, payload) =>
	makeRequest(`${URL}/api/reqs/${id}/setCompanyNote`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const getTrailerTypesRequest = () =>
	makeRequest(`${URL}/api/trailers_types?receive=all`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
	});

export const getTrailerTemperatureModesRequest = () =>
	makeRequest(`${URL}/api/modes?receive=all`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
	});

export const acceptTermsRequest = payload =>
	makeRequest(`${URL}/api/auth/accept`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});

export const logoutRequest = () =>
	makeRequest(`${URL}/api/auth/logout`, {
		method: 'POST',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const getSelectCustomUid = id =>
	makeRequest(`${URL}/api/reqs/${id}/selectCustomUid`, {
		method: 'GET',
		headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
	});

export const setSelectCustomUidRequest = (id, payload) =>
	makeRequest(`${URL}/api/reqs/${id}/setCustomUid`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			Authorization: `Bearer ${getToken()}`,
		},
		body: JSON.stringify({ ...payload }),
	});
