/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import './styles.less';
import PropTypes from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { thousandSeparator } from '../../../reqs-filter/utils';
import WeightIcon from '../../../../components/icons/weight-icon.svg';
import CountIcon from '../../../../components/icons/count-icon.svg';
import SizeIcon from '../../../../components/icons/size-icon.svg';
import LengthIcon from '../../../../components/icons/length-icon.svg';
import WidthIcon from '../../../../components/icons/width-icon.svg';
import HeightIcon from '../../../../components/icons/height-icon.svg';
import { KgIcon, VolumeIcon, PieceIcon, SizeTextIcon } from '../../../../components/text-icon';
import { StatusIndicator } from '../../../../components/status-indicator';
import { InfoIcon } from '../../../../components/info-icon';
import PencilEditIcon from '../../../../components/icons/pencil-edit-icon.svg';

const ReqsCustomModalCell = ({ rowData, onOpenModal }) => {
	if (rowData.id === 'customer') {
		return (
			<span className="reqs-custom-modal-cell__cell">
				<div className="reqs-custom-modal-cell__cell_bold">{rowData.value.name}</div>
				<div className="reqs-custom-modal-cell__cell_thin">{rowData.value.code}</div>
			</span>
		);
	}
	if (rowData.id === 'address_from' || rowData.id.includes('address_to')) {
		return (
			<div className="reqs-custom-modal-cell__address">
				<div className="reqs-custom-modal-cell__address-block">
					<div className="reqs-custom-modal-cell__address-name">{rowData.value.name}</div>
					{rowData.value?.inn && (
						<div className="reqs-custom-modal-cell__address-address">{rowData.value.inn}</div>
					)}
					<div className="reqs-custom-modal-cell__address-address">{rowData.value.address}</div>
				</div>
				<div className="reqs-custom-modal-cell__address-block">
					<div className="reqs-custom-modal-cell__address-block-item">
						<span className="reqs-custom-modal-cell__address-label">Режим работы:</span>{' '}
						{rowData.value.time}
					</div>
					<div className="reqs-custom-modal-cell__address-block-item">
						{rowData.value?.status_info?.status && (
							<div className="reqs-custom-modal-cell__address-status-indicator">
								<StatusIndicator
									status={rowData.value.status_info.status}
									description={rowData.value.status_info.description}
									position="top center"
								/>
							</div>
						)}
						<span className="reqs-custom-modal-cell__address-label">
							{rowData.id === 'address_from' ? 'Дата отгрузки:' : 'Доставить до: '}
						</span>{' '}
						{rowData.value.cargo_date}
					</div>
					{rowData.id === 'address_from' && rowData.value.is_unchanged_fetch_at ? 'Неизменная' : ''}
				</div>
			</div>
		);
	}

	if (rowData.id === 'product_invoice') {
		return (
			<span className="reqs-custom-modal-cell__product-invoice">
				<div className="reqs-custom-modal-cell__product-invoice-number">
					{rowData.value.product_invoice ?? '---'}
				</div>
				{rowData.value.action && (
					<div
						className="reqs-custom-modal-cell__product-invoice-icon"
						onClick={() => onOpenModal('product_invoice')}
					>
						<img
							className="reqs-custom-modal-cell__product-invoice-image"
							src={PencilEditIcon}
							alt="pencil-edit-icon"
						/>
					</div>
				)}
			</span>
		);
	}

	if (rowData.id === 'custom_uid') {
		return (
			<span className="reqs-custom-modal-cell__custom_uid">
				<div className="reqs-custom-modal-cell__custom_uid-number">{rowData.value.uid}</div>
				{rowData.value.action && (
					<div className="reqs-custom-modal-cell__custom_uid-icon" onClick={() => onOpenModal('custom_uid')}>
						<img
							className="reqs-custom-modal-cell__custom_uid-image"
							src={PencilEditIcon}
							alt="pencil-edit-icon"
						/>
					</div>
				)}
			</span>
		);
	}

	if (rowData.id === 'flight') {
		return (
			<span className="reqs-custom-modal-cell__flight">
				<div className="reqs-custom-modal-cell__flight-number">{rowData.value.flight}</div>
				{rowData.value?.action && (
					<div className="reqs-custom-modal-cell__flight-icon" onClick={() => onOpenModal('flight')}>
						<img
							className="reqs-custom-modal-cell__flight-image"
							src={PencilEditIcon}
							alt="pencil-edit-icon"
						/>
					</div>
				)}
			</span>
		);
	}

	if (rowData.id === 'chosen_comment') {
		return (
			<span className="reqs-custom-modal-cell__chosen_comment">
				<div className="reqs-custom-modal-cell__chosen_comment-note">{rowData.value.text}</div>
				<div
					className="reqs-custom-modal-cell__chosen_comment-icon"
					onClick={() => onOpenModal('chosen_comment')}
				>
					{rowData.value?.isButtonVisible && (
						<img
							className="reqs-custom-modal-cell__chosen_comment-image"
							src={PencilEditIcon}
							alt="pencil-edit-icon"
						/>
					)}
				</div>
			</span>
		);
	}

	if (rowData.id === 'bid') {
		return (
			<span className="reqs-custom-modal-cell__cell">
				<div className="reqs-custom-modal-cell__cell_bold">{rowData.value.count}</div>
				<div className="reqs-custom-modal-cell__cell_thin">{rowData.value.date}</div>
			</span>
		);
	}

	if (rowData.id === 'tender_type') {
		return <span className="reqs-custom-modal-cell__cell">{rowData.value.name}</span>;
	}

	if (rowData.id === 'tender_tk') {
		return (
			<span className="reqs-custom-modal-cell__cell">
				<div className="reqs-custom-modal-cell__cell_bold__tender-tk">{rowData.value.title}</div>
				<div className="reqs-custom-modal-cell__cell_thin">{rowData.value.text}</div>
			</span>
		);
	}

	if (['author', 'tk', 'driver', 'car', 'trailer', 'offer_author'].includes(rowData.id)) {
		return (
			<span className="reqs-custom-modal-cell__cell">
				<div className="reqs-custom-modal-cell__cell_bold">{rowData.value.title}</div>
				<div className="reqs-custom-modal-cell__cell_thin">{rowData.value.text}</div>
			</span>
		);
	}

	if (['cost', 'tender_amount'].includes(rowData.id)) {
		return <span className="reqs-custom-modal-cell__cell">{rowData.value}</span>;
	}

	if (rowData.id === 'sum' && rowData?.value?.sum) {
		return (
			<div className="reqs-custom-modal-cell__sum-block">
				{rowData.value.info && (
					<div className="reqs-custom-modal-cell__sum-offer-info">
						<InfoIcon popupContent={rowData.value.info} />
					</div>
				)}
				<span className="reqs-custom-modal-cell__cell">{rowData.value.sum}</span>
			</div>
		);
	}

	if (rowData.id === 'features') {
		return (
			<div className="reqs-custom-modal-cell__features">
				{Array.isArray(rowData.value) ? (
					rowData.value.map(feature => (
						<Popup
							key={feature.id}
							content={feature.name}
							trigger={
								<div className="reqs-custom-modal-cell__feature">
									<div
										className={`reqs-custom-modal-cell__icon reqs-custom-modal-cell__icon_${feature.icon}`}
									/>
								</div>
							}
							position="top center"
							size="mini"
							inverted
							style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
						/>
					))
				) : (
					<span className="reqs-custom-modal-cell__cell">{rowData.value}</span>
				)}
			</div>
		);
	}

	if (rowData.id.includes('cargo')) {
		return (
			<div className="reqs-custom-modal-cell__cargo">
				{rowData.value.weight !== null && (
					<Popup
						content={
							<p>
								<b>Масса:</b> {`${thousandSeparator(rowData.value.weight)} кг`}
							</p>
						}
						trigger={
							<div className="reqs-custom-modal-cell__cargo-item">
								<img
									className="reqs-custom-modal-cell__cargo-image"
									src={WeightIcon}
									alt="weight-icon"
								/>
								<div className="reqs-custom-modal-cell__cargo-field">
									{thousandSeparator(rowData.value.weight)}
								</div>
								{KgIcon}
							</div>
						}
						position="top center"
						size="mini"
						inverted
						style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
					/>
				)}
				{rowData.value.volume !== null && (
					<Popup
						content={
							<p>
								<b>Объем:</b> {`${thousandSeparator(rowData.value.volume)} м3`}
							</p>
						}
						trigger={
							<div className="reqs-custom-modal-cell__cargo-item">
								<img className="reqs-custom-modal-cell__cargo-image" src={SizeIcon} alt="weight-icon" />
								<div className="reqs-custom-modal-cell__cargo-field">
									{thousandSeparator(rowData.value.volume)}
								</div>
								{VolumeIcon}
							</div>
						}
						position="top center"
						size="mini"
						inverted
						style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
					/>
				)}
				{rowData.value.place !== null && (
					<Popup
						content={
							<p>
								<b>Места:</b> {`${thousandSeparator(rowData.value.place)} шт`}
							</p>
						}
						trigger={
							<div className="reqs-custom-modal-cell__cargo-item">
								<img
									className="reqs-custom-modal-cell__cargo-image"
									src={CountIcon}
									alt="weight-icon"
								/>
								<div className="reqs-custom-modal-cell__cargo-field">
									{thousandSeparator(rowData.value.place)}
								</div>
								{PieceIcon}
							</div>
						}
						position="top center"
						size="mini"
						inverted
						style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
					/>
				)}
			</div>
		);
	}

	if (rowData.id.includes('doneAt')) {
		return (
			<div className="reqs-custom-modal-cell__done-at-block-item">
				{rowData.value?.doneInfo?.status && (
					<div className="reqs-custom-modal-cell__done-at-status-indicator">
						<StatusIndicator
							status={rowData.value.doneInfo.status}
							description={rowData.value.doneInfo.description}
							position="top center"
						/>
					</div>
				)}
				<span className="reqs-custom-modal-cell__done-at-label">{rowData.value.doneAt}</span>
			</div>
		);
	}

	if (rowData.id.includes('size')) {
		return (
			<div className="reqs-custom-modal-cell__size">
				<div className="reqs-custom-modal-cell__size-row">
					{rowData.value.place !== null && (
						<Popup
							content={
								<p>
									<b>Места:</b> {`${thousandSeparator(rowData.value.place)} шт`}
								</p>
							}
							trigger={
								<div className="reqs-custom-modal-cell__cargo-item">
									<img
										className="reqs-custom-modal-cell__cargo-image"
										src={CountIcon}
										alt="place-icon"
									/>
									<div className="reqs-custom-modal-cell__cargo-field">
										{thousandSeparator(rowData.value.place)}
									</div>
									{PieceIcon}
								</div>
							}
							position="top center"
							size="mini"
							inverted
							style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
						/>
					)}
					{rowData.value.weight !== null && (
						<Popup
							content={
								<p>
									<b>Масса:</b> {`${thousandSeparator(rowData.value.weight)} кг`}
								</p>
							}
							trigger={
								<div className="reqs-custom-modal-cell__cargo-item">
									<img
										className="reqs-custom-modal-cell__cargo-image"
										src={WeightIcon}
										alt="weight-icon"
									/>
									<div className="reqs-custom-modal-cell__cargo-field">
										{thousandSeparator(rowData.value.weight)}
									</div>
									{KgIcon}
								</div>
							}
							position="top center"
							size="mini"
							inverted
							style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
						/>
					)}
				</div>
				<div className="reqs-custom-modal-cell__size-row">
					{rowData.value.length !== null && (
						<Popup
							content={
								<p>
									<b>Длина:</b> {`${thousandSeparator(rowData.value.length)} см`}
								</p>
							}
							trigger={
								<div className="reqs-custom-modal-cell__cargo-item">
									<img
										className="reqs-custom-modal-cell__cargo-image"
										src={LengthIcon}
										alt="length-icon"
									/>
									<div className="reqs-custom-modal-cell__cargo-field">
										{thousandSeparator(rowData.value.length)}
									</div>
									{SizeTextIcon}
								</div>
							}
							position="top center"
							size="mini"
							inverted
							style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
						/>
					)}
					{rowData.value.width !== null && (
						<Popup
							content={
								<p>
									<b>Ширина:</b> {`${thousandSeparator(rowData.value.width)} см`}
								</p>
							}
							trigger={
								<div className="reqs-custom-modal-cell__cargo-item">
									<img
										className="reqs-custom-modal-cell__cargo-image"
										src={WidthIcon}
										alt="width-icon"
									/>
									<div className="reqs-custom-modal-cell__cargo-field">
										{thousandSeparator(rowData.value.width)}
									</div>
									{SizeTextIcon}
								</div>
							}
							position="top center"
							size="mini"
							inverted
							style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
						/>
					)}
					{rowData.value.height !== null && (
						<Popup
							content={
								<p>
									<b>Высота:</b> {`${thousandSeparator(rowData.value.height)} см`}
								</p>
							}
							trigger={
								<div className="reqs-custom-modal-cell__cargo-item">
									<img
										className="reqs-custom-modal-cell__cargo-image"
										src={HeightIcon}
										alt="height-icon"
									/>
									<div className="reqs-custom-modal-cell__cargo-field">
										{thousandSeparator(rowData.value.height)}
									</div>
									{SizeTextIcon}
								</div>
							}
							position="top center"
							size="mini"
							inverted
							style={{ textAlign: 'center', fontSize: 12, fontWeight: '500' }}
						/>
					)}
				</div>
			</div>
		);
	}

	return rowData.value || '---';
};

ReqsCustomModalCell.propTypes = {
	rowData: PropTypes.object.isRequired,
	columnId: PropTypes.string.isRequired,
	onOpenModal: PropTypes.func,
};

export { ReqsCustomModalCell };
