/* eslint-disable no-mixed-spaces-and-tabs */
import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { getFormValues, isInvalid } from 'redux-form';
import moment from 'moment';
import { initialState } from './reducer';
import { FORM_NAME, CAR_TABLE_ROWS_NAMES, TYPES_WITH_DATE_ISSUE_AND_MODES, TYPES_WITH_TRAILER } from './constants';

export const selectCarsModalDomain = state => state.carsModalReducer || initialState;
export const selectRouterDomain = state => state.router || {};
export const selectProfileDomain = state => state.profileModalReducer || {};

export const getCarInfoSelector = () =>
	createSelector(selectCarsModalDomain, ({ carInfo }) => {
		if (!carInfo) {
			return [];
		}

		const modesNames = {};

		const modes = {};

		carInfo?.modes.forEach((el, index) => {
			modesNames[`mode${index + 1}`] = `${el.mode.name} °C`;
			modes[`mode${index + 1}`] = {
				validate: el?.is_validated ? 'Да' : 'Нет',
				date: el?.date ? moment(el?.date).format('DD.MM.YYYY') : 'Нет',
			};
			CAR_TABLE_ROWS_NAMES[`mode${index + 1}`] = `Температурный режим #${index + 1}`;
		});

		const data = {
			number: carInfo.number || '-',
			vin: carInfo.vin || '-',
			type: carInfo.type?.name || '-',
			dateIssue: carInfo.date_issue ? moment(carInfo.date_issue).format('MM.YYYY') : '-',
			...(carInfo.type_date_issue ? { typeDateIssue: moment(carInfo.type_date_issue).format('MM.YYYY') } : {}),
			tonnage: carInfo.tonnage?.name ? carInfo.tonnage?.name : '-',
			trailer: carInfo.trailer?.name ? `${carInfo.trailer?.name}, ${carInfo.trailer?.number}` : 'Нет',
			...modesNames,
			...(carInfo.is_accredited !== null ? { isAccredited: carInfo.is_accredited ? 'Да' : 'Нет' } : {}),
			author: {
				name: `${carInfo.user?.last_name || '-'} ${carInfo.user?.first_name || '-'}`,
				role: carInfo.user?.role.name || '-',
			},
		};

		return Object.entries(data).reduce((result, [key, value]) => {
			return [...result, { id: key, title: CAR_TABLE_ROWS_NAMES[key], value, mode: modes[key] }];
		}, []);
	});

export const getIsCarInfoLoadingSelector = () =>
	createSelector(
		selectCarsModalDomain,
		({ isCarInfoLoading, isCarDeleting, isCarSaving }) => isCarInfoLoading || isCarDeleting || isCarSaving,
	);

export const getCarNameSelector = () => createSelector(selectCarsModalDomain, ({ carInfo }) => carInfo?.name || '-');

export const getIsModalShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'car');
	});

export const getIsAuditTabShownSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'car' && query?.tab === 'audit');
	});

export const getActiveCarIdSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => (query?.modal === 'car' ? query?.id : null));

export const getIsCarAddFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'car' && query?.mode === 'add');
	});

export const getIsCarEditFormOpenSelector = () =>
	createSelector(selectRouterDomain, ({ location: { query } }) => {
		return Boolean(!isEmpty(query) && query?.modal === 'car' && Boolean(query?.id) && query?.mode === 'edit');
	});

export const getFormInitialValuesSelector = () =>
	createSelector(selectCarsModalDomain, ({ carInfo }) => {
		if (!carInfo) {
			return {
				tonnage_id: 'none',
				modes: [{}],
			};
		}

		return {
			number: carInfo.number,
			vin: carInfo.vin,
			name: carInfo.name,
			type_id: String(carInfo.type?.id),
			date_issue: carInfo.date_issue,
			type_date_issue: carInfo.type_date_issue,
			trailer: carInfo.trailer?.id,
			tonnage_id: carInfo.tonnage?.id ? String(carInfo.tonnage?.id) : 'none',
			is_accredited: carInfo.is_accredited,
			modes: carInfo.modes?.length
				? carInfo.modes?.map(mode => ({
						mode_id: String(mode.mode?.id),
						is_validated: mode.is_validated,
						date: mode.date ? moment(mode.date).format('YYYY-MM-DD') : '',
				  }))
				: [{}],
		};
	});

export const getDataForCreateOrUpdateCarSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => {
		const result = {
			name: formValues.name,
			number: formValues.number,
			vin: formValues.vin,
			type_id: formValues.type_id,
			tonnage_id: formValues.tonnage_id === 'none' ? null : formValues.tonnage_id,
			date_issue: formValues.date_issue,
			is_accredited: formValues.is_accredited,
		};

		if (TYPES_WITH_DATE_ISSUE_AND_MODES.includes(formValues.type_id)) {
			result.type_date_issue = formValues.type_date_issue;

			const modes = formValues.modes?.reduce((acc, mode) => {
				if (!isEmpty(mode)) {
					acc.push({ ...mode, is_validated: Boolean(mode.is_validated) });
				}

				return acc;
			}, []);

			if (modes.length) {
				result.modes = modes;
			}
		}

		if (TYPES_WITH_TRAILER.includes(formValues.type_id)) {
			result.trailer_id = formValues.trailer;
		}

		return result;
	});

export const getTrailersListSelector = () => createSelector(selectCarsModalDomain, ({ trailersList }) => trailersList);

export const getIsTrailersListLoadingSelector = () =>
	createSelector(selectCarsModalDomain, ({ isTrailersListLoading }) => isTrailersListLoading);

export const getFormIsInvalidSelector = () => createSelector(isInvalid(FORM_NAME), isInvalid => isInvalid);

export const getTonnagesSelector = () => createSelector(selectCarsModalDomain, ({ tonnages }) => tonnages);

export const getCarTypesSelector = () => createSelector(selectCarsModalDomain, ({ carTypes }) => carTypes);

export const getModesSelector = () => createSelector(selectCarsModalDomain, ({ modes }) => modes);

export const getIsTonnagesLoadingSelector = () =>
	createSelector(selectCarsModalDomain, ({ isTonnagesLoading }) => isTonnagesLoading);

export const getIsCarTypesLoadingSelector = () =>
	createSelector(selectCarsModalDomain, ({ isCarTypesLoading }) => isCarTypesLoading);

export const getIsModesLoadingSelector = () =>
	createSelector(selectCarsModalDomain, ({ isModesLoading }) => isModesLoading);

export const getIsTypeDateIssueVisibleSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => {
		return TYPES_WITH_DATE_ISSUE_AND_MODES.includes(formValues?.type_id);
	});

export const getIsTrailerVisibleSelector = () =>
	createSelector(getFormValues(FORM_NAME), formValues => {
		return TYPES_WITH_TRAILER.includes(formValues?.type_id);
	});

export const getIsAdminSelector = () =>
	createSelector(selectProfileDomain, ({ userInfo }) => {
		return userInfo?.company?.id === 1;
	});

export const getIsAvailableTrailersSelector = () =>
	createSelector(selectProfileDomain, ({ userRules }) => {
		return Boolean(userRules?.trailers?.create);
	});
